@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/icon';
@use '../global/media';

.ce_rsce_booknav {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;

    a {
        position: relative;
        display: block;
        padding: 14px 5px 10px 40px;
        font-size: 0.8em;
        line-height: 1.625;
        text-decoration: none;
        border-bottom: 1px solid;
        @include color.layout(color, font);
        @include color.layout(border-bottom-color, font);

        @include icon.link(layout, 7) {
            position: absolute;
            top: 19px;
            left: 5px;
        }

        &:hover {
            background: map.get(color.$default, light);
            transition: background 0.3s;
        }

        &.prev {
            display: none;
        }

        strong {
            font-weight: map.get(font.$font-weight, medium);
        }
    }

    @include media.screen(tablet) {
        a {
            width: calc(50% - 30px);
            padding-left: 50px;

            @include icon.link(layout) {
                top: 24px;
            }

            &.prev {
                display: block;
                margin-right: 60px;

                &::before {
                    left: 0;
                    transform: rotateZ(180deg);
                }
            }
        }
    }
}
