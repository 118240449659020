@use '../global/color';
@use '../global/media';

.print-share {
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;

    &__print,
    &__share {
        position: relative;
        display: inline-block;
        width: 98px;
        height: 98px;
        margin: 0 15px;
        color: #959594;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        text-transform: uppercase;
        vertical-align: top;
        cursor: pointer;

        svg {
            stroke: #959594;
        }

        span {
            position: absolute;
            right: 0;
            bottom: 10px;
            left: 0;
        }

        &:hover {
            @include color.layout(color, dark);

            svg {
                @include color.layout(stroke, dark);
            }
        }
    }

    &__print {
        padding-top: 27px;
    }

    &__share {
        padding-top: 33px;
    }

    @include media.screen(desktop) {
        position: inherit;
        bottom: auto;
        float: right;
        width: auto;

        .layout--meta & {
            margin-bottom: -100px;
        }

        &__print,
        &__share {
            border: 1px solid #959594;

            &:hover {
                @include color.layout(border-color, regular);
            }
        }
    }
}
