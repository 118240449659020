@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/icon';
@use '../global/media';

.news-reader {
    .back a {
        display: inline-block;
        height: 45px;
        margin-top: 45px;
        padding: 9px 40px 0 31px;
        color: #fff;
        font-weight: map.get(font.$font-weight, medium);
        font-size: 13px;
        line-height: 31px;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        background: map.get(color.$news, regular);

        @include icon.link(button, 9, before) {
            display: inline-block;
            margin-right: 20px;
            transform: rotateY(180deg);
        }

        @include media.screen(desktop) {
            height: 61px;
            padding-top: 17px;
            font-size: 18px;
        }
    }
}

@include media.screen(tablet) {
    .news-reader,
    .layout--news .section-main {
        h1 {
            font-size: 42px !important;
            line-height: 48px !important;
        }
    }
}
