@use '~handorgel/lib/css/handorgel';
@use '../global/color';
@use '../global/media';

.handorgel {
    width: auto;
    border: none;

    &__header {
        position: relative;
        margin-top: 3px;

        &::before {
            position: absolute;
            top: 13px;
            right: 30px;
            width: 1px;
            height: 21px;
            background: #fff;
            transform: rotateX(0deg);
            transition: transform 0.1s linear;
            content: '';
        }

        &::after {
            position: absolute;
            top: 23px;
            right: 20px;
            width: 21px;
            height: 1px;
            background: #fff;
            content: '';
        }

        &--open {
            &::before {
                transform: rotateX(90deg);
            }
        }
    }

    &__button {
        display: block;
        width: 100%;
        padding: 10px 60px 10px 20px;
        overflow: hidden;
        color: #fff;
        font-size: 16px;
        line-height: 26px;
        text-align: left;
        text-overflow: ellipsis;
        border: 0;
        outline: none;
        cursor: pointer;
        transition: background 0.3s;
        @include color.layout(background, regular);

        &:hover {
            @include color.layout(background, dark);
        }
    }

    &__content {
        padding: 0 20px;
        border: 1px solid;
        @include color.layout(border-color, regular);

        > div {
            overflow: hidden;
        }
    }

    @include media.screen(tablet) {
        &__header {
            &::before {
                top: 25px;
                right: 45px;
                height: 31px;
            }

            &::after {
                top: 40px;
                right: 30px;
                width: 31px;
            }
        }

        &__button {
            padding: 25px 100px 25px 50px;
            font-size: 22px;
        }

        &__content {
            padding: 0 45px;
            font-size: 22px;
            line-height: 40px;

            p {
                margin: 35px 0;
            }
        }
    }
}
