@use 'sass:map';
@use 'defaults';
@use 'global/color';
@use 'global/media';

@import 'modules/dropdown-subnavigation';
@import 'modules/current-page';
@import 'modules/print-share';
@import 'elements/handorgel';
@import 'elements/booknav';
@import 'modules/pagination';
@import 'modules/sitemap';
@import 'modules/news-reader';

.section-main {
    padding-top: 1px;
    padding-bottom: 120px;

    @include media.screen(desktop) {
        padding-bottom: 100px;
    }

    .mod_article__inside {
        margin: 0 20px;

        h1 {
            font-size: 26px;
            line-height: 32px;
            @include color.layout(color, font);
        }

        h2 {
            margin-top: 2em;
            font-size: 19px;
            line-height: 28px;
            @include color.layout(color, font);
        }

        h3 {
            margin-top: 1em;
            @include color.layout(color, font);
        }

        @include media.screen(tablet) {
            max-width: 1184px;
            margin: 0 auto;
            padding: 0 70px;
            font-size: 24px;
            line-height: 42px;

            h1 {
                font-size: 52px;
                line-height: 58px;
            }

            h2 {
                font-size: 30px;
                line-height: 47px;
            }
        }

        h1 + h2,
        h1 + .content-text > h2 {
            margin-top: 1em;
        }
    }

    .content-text,
    .handorgel__content {
        a {
            @include color.layout(color, font);
            border-bottom: 1px solid;
            transition: color 0.3s;

            &:hover {
                @include color.layout(color, regular);
            }
        }

        .image_container {
            margin-bottom: 1em;

            &.float_right {
                max-width: 40%;
                margin-left: 1em;
            }

            &.float_left {
                max-width: 40%;
                margin-right: 1em;
            }

            &.float_below {
                margin-top: 1em;
            }

            @include media.screen(tablet) {
                &.float_right {
                    margin-left: 2em;
                }

                &.float_left {
                    margin-right: 2em;
                }
            }
        }
    }

    .content-text,
    .content-list,
    .handorgel__content {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                position: relative;
                margin: 1em 0 0;
                padding: 0 0 0.8em 20px;
                border-bottom: 1px solid map.get(color.$default, regular);

                &::before {
                    position: absolute;
                    top: 8px;
                    left: 0;
                    width: 6px;
                    height: 6px;
                    border-right: 1px solid map.get(color.$default, font);
                    border-bottom: 1px solid map.get(color.$default, font);
                    transform: rotateZ(-45deg);
                    content: '';
                }

                &.no-border {
                    border-bottom: 0;
                }
            }

            @include media.screen(tablet) {
                li {
                    padding-left: 30px;

                    &::before {
                        top: 15px;
                        width: 7px;
                        height: 7px;
                    }
                }
            }
        }
    }

    .content-list,
    .content-text ul:last-child,
    .handorgel__content ul:last-child {
        margin-bottom: 2em;
    }

    .ce_rsce_sources {
        margin-top: 60px;
        font-size: 12px;
        border-top: 1px solid #9b9b9b;

        ul,
        li {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        li {
            margin-bottom: 1.5em;
        }

        h2 {
            margin-top: 1em;
        }

        @include media.screen(tablet) {
            font-size: 16px;
            line-height: 26px;
        }
    }

    .mod_search {
        h1 {
            margin: 0;
            padding: 30px 0 0;
        }

        h3 {
            margin-bottom: 0;
        }

        p {
            margin: 0;
        }

        .url {
            font-size: 12px;
        }

        @include media.screen(tablet) {
            h1 {
                padding-top: 80px;
            }

            .url {
                font-size: 18px;
            }
        }
    }
}
