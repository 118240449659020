@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/icon';
@use '../global/media';

.dropdown-subnavigation {
    display: none;

    @include media.screen(desktop) {
        display: block;
        float: left;
        margin-top: 15px;

        &::after {
            display: table;
            clear: both;
            content: '';
        }

        ul,
        li {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        .level_1 {
            > li {
                float: left;
                margin-right: 1px;

                > a,
                > strong {
                    position: relative;
                    display: block;
                    padding: 10px 50px 20px 30px;
                    color: map.get(color.$default, dark);
                    font-weight: map.get(font.$font-weight, regular);
                    text-transform: uppercase;
                    text-decoration: none;
                    background: map.get(color.$default, light);

                    &::after {
                        position: absolute;
                        top: 32px;
                        right: 28px;
                        width: 0;
                        height: 0;
                        font-size: 0;
                        line-height: 0;
                        border-top: 7px solid map.get(color.$default, dark);
                        border-right: 5px solid transparent;
                        border-left: 5px solid transparent;
                        content: '';
                    }

                    &.page-icon {
                        &--alcohol {
                            @include icon.topic(alcohol, 32, default) {
                                position: relative;
                                top: 6px;
                                display: inline-block;
                                margin-right: 5px;
                            }
                        }

                        &--drugs {
                            @include icon.topic(drugs, 32, default) {
                                position: relative;
                                top: 8px;
                                display: inline-block;
                                margin-right: 5px;
                            }
                        }

                        &--opiate {
                            @include icon.topic(opiate, 32, default) {
                                position: relative;
                                top: 6px;
                                display: inline-block;
                                margin-right: 5px;
                            }
                        }

                        &--help {
                            @include icon.topic(help, 32, default) {
                                position: relative;
                                top: 6px;
                                display: inline-block;
                                margin-right: 5px;
                            }
                        }
                    }

                    &:hover {
                        color: #fff;
                        background: map.get(color.$default, regular);

                        &::after {
                            border-top-color: #fff;
                        }

                        &.page-icon {
                            &--alcohol {
                                @include icon.topic(alcohol, 32, white);
                            }

                            &--drugs {
                                @include icon.topic(drugs, 32, white);
                            }

                            &--opiate {
                                @include icon.topic(opiate, 32, white);
                            }

                            &--help {
                                @include icon.topic(help, 32, white);
                            }
                        }
                    }

                    &.active,
                    &.trail {
                        color: #fff;
                        font-weight: map.get(font.$font-weight, bold);
                        @include color.layout(background, regular);

                        &::after {
                            border-top-color: #fff;
                        }

                        &.page-icon {
                            &--alcohol {
                                @include icon.topic(alcohol, 32, white);
                            }

                            &--drugs {
                                @include icon.topic(drugs, 32, white);
                            }

                            &--opiate {
                                @include icon.topic(opiate, 32, white);
                            }

                            &--help {
                                @include icon.topic(help, 32, white);
                            }
                        }
                    }
                }
            }
        }

        .level_2 {
            position: absolute;
            z-index: 100;
            background: map.get(color.$default, light);
            border-top: 1px solid #fff;
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.3s linear;

            > li {
                border-top: 1px solid rgb(255 255 255 / 50%);
                transition: border-color 0.3s;

                &:first-child {
                    border-top: none;
                }

                &:hover,
                &.active {
                    border-top-color: transparent;

                    + li {
                        border-top-color: transparent;
                    }
                }

                > a,
                > strong {
                    display: block;
                    padding: 15px 80px 15px 35px;
                    color: map.get(color.$default, dark);
                    font-size: 18px;
                    line-height: 25px;
                    text-decoration: none;
                }

                > strong,
                > a:hover {
                    color: #fff;
                    background: map.get(color.$default, regular);
                    transition:
                        background 0.3s,
                        color 0.3s;
                }
            }
        }

        .level_1 > li.active .level_2,
        .level_1 > li.trail .level_2 {
            @include color.layout(background, regular);

            > li > a,
            > li > strong {
                color: #fff;
                font-weight: map.get(font.$font-weight, regular);
            }

            > li > strong,
            > li > a:hover {
                @include color.layout(background, dark);
            }
        }

        .level_1 > li:hover {
            .level_2 {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
