@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/media';

.current-page {
    position: relative;
    margin: 30px 20px 0 0;
    padding-bottom: 10px;
    @include color.layout(color, font);
    font-size: 12px;
    text-transform: uppercase;

    .layout--overview & {
        margin-left: 20px;
    }

    &::after {
        position: absolute;
        bottom: 0;
        left: 2px;
        width: 50px;
        height: 2px;
        content: '';
        @include color.layout(background, font);
    }

    &__title {
        font-weight: map.get(font.$font-weight, medium);
    }

    @include media.screen(tablet) {
        max-width: 1184px;
        margin-right: auto !important;
        margin-left: auto !important;
        padding-bottom: 25px;
        padding-left: 0;
        font-size: 18px;

        &::after {
            left: 0;
            width: 90px;
            height: 3px;
        }

        .layout--overview & {
            padding-left: 70px;

            &::after {
                left: 70px;
            }
        }
    }

    @include media.screen(desktop) {
        margin-top: 0;
    }
}
