@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/icon';
@use '../global/media';

.pagination {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;

    ul,
    li,
    p {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    > p {
        margin: 0 5px;
        color: map.get(color.$default, regular);
        font-weight: map.get(font.$font-weight, medium);
        font-size: 0.6em;
    }

    ul {
        display: flex;
        justify-content: center;
    }

    li {
        &.first,
        &.last {
            display: none;
        }
    }

    a,
    strong {
        position: relative;
        display: block;
        width: 2.5em;
        height: 2.5em;
        margin: 0 1px;
        font-size: 0.75em;
        line-height: 2.7em;
        text-align: center;

        &.active,
        &:hover {
            background: map.get(color.$default, light);
            transition: background 0.3s;
        }

        &.active {
            font-weight: map.get(font.$font-weight, bold);
        }

        &.previous,
        &.next {
            width: 59px;
            text-indent: -999em;

            @include icon.link(default) {
                position: absolute;
                top: 11px;
                left: 10px;
            }
        }

        &.previous {
            &::before {
                transform: rotateZ(180deg);
            }
        }
    }

    @include media.screen(tablet) {
        margin-top: 30px;
        margin-bottom: 0;

        a {
            &.previous,
            &.next {
                width: 69px;

                &::before {
                    top: 19px;
                    left: 15px;
                }
            }
        }
    }
}
