@use 'sass:map';
@use '../global/color';
@use '../global/font';
@use '../global/media';

.mod_sitemap {
    .page-icon--home {
        display: none;
    }

    ul,
    li {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    a {
        display: block;
        padding: 8px 30px 4px;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        border-bottom: 1px solid map.get(color.$default, regular);

        &:hover {
            background-color: map.get(color.$default, light);
        }
    }

    .level_1 {
        > li {
            margin-bottom: 32px;

            > a {
                font-weight: map.get(font.$font-weight, regular);
                font-size: 19px;
                border-bottom: 2px solid map.get(color.$default, dark);
            }
        }
    }

    .level_2 {
        > li {
            margin-bottom: 22px;

            > a {
                color: map.get(color.$default, font);
                font-weight: map.get(font.$font-weight, bold);
                text-transform: uppercase;
            }
        }
    }

    .level_4 {
        > li {
            > a {
                padding-left: 60px;
            }
        }
    }

    .level_5 {
        > li {
            > a {
                padding-left: 100px;
            }
        }
    }

    .level_1 {
        > li.page-topic--seniors {
            > a {
                border-color: map.get(color.$seniors, regular);
            }

            .level_2 > li > a {
                color: map.get(color.$seniors, regular);
            }
        }

        > li.page-topic--relatives {
            > a {
                border-color: map.get(color.$relatives, regular);
            }

            .level_2 > li > a {
                color: map.get(color.$relatives, regular);
            }
        }

        > li.page-topic--experts {
            > a {
                border-color: map.get(color.$experts, regular);
            }

            .level_2 > li > a {
                color: map.get(color.$experts, regular);
            }
        }

        > li.page-topic--doctors {
            > a {
                border-color: map.get(color.$doctors, regular);
            }

            .level_2 > li > a {
                color: map.get(color.$doctors, regular);
            }
        }

        > li.page-topic--news {
            > a {
                border-color: map.get(color.$news, regular);
            }

            .level_2 > li {
                margin-bottom: 0;

                > a {
                    font-weight: inherit;
                    text-transform: none;
                }
            }
        }
    }

    @include media.screen(tablet) {
        .level_1 {
            display: flex;
            flex-wrap: wrap;

            > li {
                width: 50%;

                &:nth-child(even) {
                    padding-right: 40px;
                }

                &:nth-child(odd) {
                    padding-left: 40px;
                }
            }
        }
    }

    @include media.screen(desktop) {
        h1 {
            margin-bottom: 100px;
        }

        a {
            padding: 18px 30px 14px;
            font-size: 24px;
            line-height: 36px;
        }

        .level_1 {
            > li {
                margin-bottom: 62px;

                > a {
                    font-size: 36px;
                    border-bottom-width: 3px;
                }
            }
        }

        .level_2 {
            > li {
                margin-bottom: 32px;
            }
        }
    }
}
